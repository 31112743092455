.alert {
  width: 350px;
  padding: 8px 0;
}

.top {
  padding: 16px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4px;
}

.top.withIcon {
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  padding: 8px 20px;
}

.middle {
  padding: 16px 20px;
}

.middle.number {
  text-align: center;
}

.bottom {
  padding: 16px 20px;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.icon {
  cursor: pointer;
}

.middleIconParagraph {
  display: flex;
  padding: 0 20px 16px 20px;
  gap: 8px;
  align-items: center;
  justify-content: center;
}