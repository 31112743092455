.pillWeb {
  width: max-content;
  height: 32px;

  border: 1px solid var(--still400);
  border-radius: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;

  padding: 7px 12px;
}

.selected {
  color: var(--white) !important;
  background-color: var(--still800) !important;
}

.disabled {
  color: var(--still400);
}

.interactive {
  cursor: pointer;
}

.interactive:hover,
.interactive:focus {
  color: var(--still800);
  border-color: var(--still300);
  background-color: var(--still200);
  outline: var(--still300);
}

.interactive:focus-visible {
  /* default focus ring for Firefox */
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
}

.label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
