@import url('./animations.css');
@import url('./bellColors.css');
@import url('./colors.css');
@import url('./reset.css');
@import url('./sizes.css');

body {
  font-family: Wigrum, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--still100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  ascent-override: 90%;
}

@font-face {
  font-family: Wigrum;
  src: url('Assets/Fonts/Wigrum/Wigrum-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  ascent-override: 90%;
}
